import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header12';
import Footer from '../layout/footer4';
import { useForm } from 'react-hook-form';
import { login } from '../server';
import Swal from 'sweetalert2';

const bg = require('../../images/banner/hk.jpg');

function ShopLogin() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const submitFrom = async (data) => {
    console.log('data', data);

    login(data)
      .then((res) => {
        console.log('res', res);
        if (res?.data) {
          localStorage.setItem('userId', res?.data?.userId);
          localStorage.setItem('access_token', res?.data?.access_token);
           window.location.href = '/check-certificate';
        }
      })
      .catch((err) => {
        console.log('err', err);
        Swal.fire({
          title: 'Error!',
          text: 'Unauthorized Access Detected!!!',
          icon: 'error',
        });
      });
  };

  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: 'url(' + bg + ')' }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Login</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Login</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="section-full content-inner shop-account">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="font-weight-700 m-t0 m-b40">
                  Login To Show Your Certificates
                </h2>
              </div>
            </div>
            <div className="row dzseth" style={{ justifyContent: 'center' }}>
              <div className="col-lg-6 col-md-6 m-b30">
                <div className="p-a30 border-1 seth">
                  <div className="tab-content nav">
                    <form
                      id="login"
                      className="tab-pane active col-12 p-a0 "
                      onSubmit={handleSubmit(submitFrom)}
                    >
                      <h4 className="font-weight-700">LOGIN</h4>
                      <p className="font-weight-600">
                        If you have an account with us, please log in.
                      </p>
                      <div className="form-group">
                        <label className="font-weight-700">Company Name *</label>
                        <input
                          name="dzName"
                          required=""
                          className="form-control"
                          placeholder="Your Company Name"
                          type="text"
                          {...register('username')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700">Certificate No *</label>
                        <input
                          name="dzName"
                          required=""
                          className="form-control "
                          placeholder="Type Certificate No Here"
                           type="password"
                          {...register('password')}
                        />
                      </div>
                      <div className="text-left">
                        <button className="site-button m-r5 button-lg radius-no">
                          login
                        </button>
                        {/* <Link data-toggle="tab" to="#forgot-password" className="m-l5"><i className="fa fa-unlock-alt"></i> Forgot Password</Link>  */}
                      </div>
                    </form>
                    {/* <form id="forgot-password" className="tab-pane fade col-12 p-a0">
                                                <h4 className="font-weight-700">FORGET PASSWORD ?</h4>
                                                <p className="font-weight-600">We will send you an email to reset your password. </p>
                                                <div className="form-group">
                                                    <label className="font-weight-700">E-MAIL *</label>
                                                    <input name="dzName" required="" className="form-control" placeholder="Your Email Id" type="email"/>
                                                </div>
                                                <div className="text-left"> 
                                                    <Link className="site-button outline gray button-lg radius-no" data-toggle="tab" to="#login">Back</Link>
                                                    <button className="site-button pull-right button-lg radius-no" type='submit'>Submit</button>
                                                </div>
                                            </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
    </>
  );
}
export default ShopLogin;


